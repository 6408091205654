/* Colors and styling */
:root {
    --PrimaryColor: hsl(120, 100%, 16.1%);
    --SecondaryColor: hsl(145, 63%, 43%);
    --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 248, 246);
    --cardBG: rgb(225, 225, 235);
}

.bookingDetails {
    width: 100%;
    position: absolute;
    top: 0;

    .bookingDetailsContainer {
        width: 100%;
        height: 100%;

        z-index: 4000;
        position: relative;
        background: white;
        min-height: 70vh;

        .topBarContainer {
            padding: 11px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            background: rgb(0, 178, 138);
            padding: 11px;
            color: white;

            .closeIcon {
                cursor: pointer;
            }

            .topBarTitle {
                display: flex;
                justify-content: space-between;

                .titleContainer {
                    font-weight: 550;
                }

                .iconContainer {
                    font-size: 30px;
                }
            }
        }

        .mr_top_10 {
            margin-top: 10px !important;
        }

        .hotelDetailsContainer {
            border: 1px solid black;
            padding: 10px;
            margin: 10px;
            background: white;
            position: relative;
            margin-top: -10px;
            border-radius: 10px;

            .bookingId {
                font-size: 12px;
                border-bottom: 1px dotted black;
                padding: 10px;
            }

            .hotelDetails {
                padding: 10px;
                border-bottom: 1px dotted black;

                .hotelAddress {
                    font-size: 12px;
                }
            }

            .checkInCheckOut {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                font-size: 14px;
                border-bottom: 1px dotted black;

                .daysCount {
                    height: fit-content;
                    border: 1px solid black;
                    padding: 2px;
                    border-radius: 5px;
                }
            }

            .guestDetails {
                padding: 10px;
                border-bottom: 1px dotted black;
                font-size: 13px;

                .roomType {
                    font-size: 12px;
                }

            }
        }

        .busDetailsContainer {
            border: 1px solid black;
            padding: 10px;
            margin: 10px;
            background: white;
            position: relative;
            margin-top: -10px;
            border-radius: 10px;

            .bookingId {
                font-size: 12px;
                border-bottom: 1px dotted black;
                padding: 10px;
            }

            .busDetails {
                padding: 10px;
                border-bottom: 1px dotted black;
                display: flex;
                justify-content: space-between;

                .journeyDate {
                    background: var(--cardBG);
                    font-weight: 600;
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }

            .busTravelInfo {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                border-bottom: 1px dotted black;
            }

            .seatInfo {
                display: flex;
                gap: 20px;
                padding: 10px;
                border-bottom: 1px dotted black;

                .iconContainer {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    gap: 15px;
                }

                .seatNumber {
                    display: flex;
                    gap: 10px;

                    p {
                        background: var(--cardBG);
                        color: var(--blackColor);
                        padding: 8px;
                        font-weight: 600;
                    }
                }
            }

            .bus_contact_info {
                display: flex;
                padding: 10px;
                // align-items: center;
                justify-content: center;

                .call_info {
                    align-items: center;
                    // justify-content: center;
                    display: flex;
                    flex-direction: column;

                    .icon {
                        color: rgb(34, 152, 218);
                    }

                    div {
                        span {
                            font-size: 13px;
                            color: rgb(34, 152, 218);
                        }
                    }
                }
            }

        }

        .cabDetailsContainer {
            border: 1px solid black;
            padding: 10px;
            margin: 10px;
            background: white;
            position: relative;
            margin-top: -10px;
            border-radius: 10px;

            .bookingId {
                font-size: 12px;
                border-bottom: 1px dotted black;
                padding: 10px;
            }

            .cabDetails {
                padding: 10px;
                border-bottom: 1px dotted black;
                display: flex;
                justify-content: space-between;

                .journeyDate {
                    background: var(--cardBG);
                    font-weight: 600;
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }

            .cabTravelInfo {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                border-bottom: 1px dotted black;
            }

            .seatInfo {
                display: flex;
                gap: 20px;
                padding: 10px;
                border-bottom: 1px dotted black;

                .iconContainer {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    gap: 15px;
                }

                .seatNumber {
                    display: flex;
                    gap: 10px;

                    p {
                        background: var(--cardBG);
                        color: var(--blackColor);
                        padding: 8px;
                        font-weight: 600;
                    }
                }
            }

            .cab_contact_info {
                display: flex;
                padding: 10px;
                // align-items: center;
                justify-content: center;

                .call_info {
                    align-items: center;
                    // justify-content: center;
                    display: flex;
                    flex-direction: column;

                    .icon {
                        color: rgb(34, 152, 218);
                    }

                    div {
                        span {
                            font-size: 13px;
                            color: rgb(34, 152, 218);
                        }
                    }
                }
            }

        }

        .campDetailsContainer {
            border: 1px solid black;
            padding: 10px;
            margin: 10px;
            background: white;
            position: relative;
            margin-top: -10px;
            border-radius: 10px;

            .bookingId {
                font-size: 12px;
                border-bottom: 1px dotted black;
                padding: 10px;
            }

            .campDetails {
                padding: 10px;
                border-bottom: 1px dotted black;

                .campAddress {
                    font-size: 12px;
                }
            }

            .checkInCheckOut {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                font-size: 14px;
                border-bottom: 1px dotted black;

                .daysCount {
                    height: fit-content;
                    border: 1px solid black;
                    padding: 2px;
                    border-radius: 5px;
                }
            }

            .guestDetails {
                padding: 10px;
                border-bottom: 1px dotted black;
                font-size: 13px;

                .roomType {
                    font-size: 12px;
                }

            }
            .camp_contact_info {
                display: flex;
                padding: 10px;
                // align-items: center;
                justify-content: center;

                .call_info {
                    align-items: center;
                    // justify-content: center;
                    display: flex;
                    flex-direction: column;

                    .icon {
                        color: rgb(34, 152, 218);
                    }

                    div {
                        span {
                            font-size: 13px;
                            color: rgb(34, 152, 218);
                        }
                    }
                }
            }
        }

    }
}