/* Colors and styling */
:root {
  --PrimaryColor: hsl(120, 100%, 16.1%);
  --SecondaryColor: hsl(145, 63%, 43%);
  --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 248, 246);
  --cardBG: rgb(225, 225, 235);
}

.bookingDetails {
  width: 100%;
  position: absolute;
  top: 0;
}
.bookingDetails .bookingDetailsContainer {
  width: 100%;
  height: 100%;
  z-index: 4000;
  position: relative;
  background: white;
  min-height: 70vh;
}
.bookingDetails .bookingDetailsContainer .topBarContainer {
  padding: 11px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: rgb(0, 178, 138);
  padding: 11px;
  color: white;
}
.bookingDetails .bookingDetailsContainer .topBarContainer .closeIcon {
  cursor: pointer;
}
.bookingDetails .bookingDetailsContainer .topBarContainer .topBarTitle {
  display: flex;
  justify-content: space-between;
}
.bookingDetails .bookingDetailsContainer .topBarContainer .topBarTitle .titleContainer {
  font-weight: 550;
}
.bookingDetails .bookingDetailsContainer .topBarContainer .topBarTitle .iconContainer {
  font-size: 30px;
}
.bookingDetails .bookingDetailsContainer .mr_top_10 {
  margin-top: 10px !important;
}
.bookingDetails .bookingDetailsContainer .hotelDetailsContainer {
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
  background: white;
  position: relative;
  margin-top: -10px;
  border-radius: 10px;
}
.bookingDetails .bookingDetailsContainer .hotelDetailsContainer .bookingId {
  font-size: 12px;
  border-bottom: 1px dotted black;
  padding: 10px;
}
.bookingDetails .bookingDetailsContainer .hotelDetailsContainer .hotelDetails {
  padding: 10px;
  border-bottom: 1px dotted black;
}
.bookingDetails .bookingDetailsContainer .hotelDetailsContainer .hotelDetails .hotelAddress {
  font-size: 12px;
}
.bookingDetails .bookingDetailsContainer .hotelDetailsContainer .checkInCheckOut {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px dotted black;
}
.bookingDetails .bookingDetailsContainer .hotelDetailsContainer .checkInCheckOut .daysCount {
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid black;
  padding: 2px;
  border-radius: 5px;
}
.bookingDetails .bookingDetailsContainer .hotelDetailsContainer .guestDetails {
  padding: 10px;
  border-bottom: 1px dotted black;
  font-size: 13px;
}
.bookingDetails .bookingDetailsContainer .hotelDetailsContainer .guestDetails .roomType {
  font-size: 12px;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer {
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
  background: white;
  position: relative;
  margin-top: -10px;
  border-radius: 10px;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .bookingId {
  font-size: 12px;
  border-bottom: 1px dotted black;
  padding: 10px;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .busDetails {
  padding: 10px;
  border-bottom: 1px dotted black;
  display: flex;
  justify-content: space-between;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .busDetails .journeyDate {
  background: var(--cardBG);
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .busTravelInfo {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px dotted black;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .seatInfo {
  display: flex;
  gap: 20px;
  padding: 10px;
  border-bottom: 1px dotted black;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .seatInfo .iconContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 15px;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .seatInfo .seatNumber {
  display: flex;
  gap: 10px;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .seatInfo .seatNumber p {
  background: var(--cardBG);
  color: var(--blackColor);
  padding: 8px;
  font-weight: 600;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .bus_contact_info {
  display: flex;
  padding: 10px;
  justify-content: center;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .bus_contact_info .call_info {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .bus_contact_info .call_info .icon {
  color: rgb(34, 152, 218);
}
.bookingDetails .bookingDetailsContainer .busDetailsContainer .bus_contact_info .call_info div span {
  font-size: 13px;
  color: rgb(34, 152, 218);
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer {
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
  background: white;
  position: relative;
  margin-top: -10px;
  border-radius: 10px;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .bookingId {
  font-size: 12px;
  border-bottom: 1px dotted black;
  padding: 10px;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .cabDetails {
  padding: 10px;
  border-bottom: 1px dotted black;
  display: flex;
  justify-content: space-between;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .cabDetails .journeyDate {
  background: var(--cardBG);
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .cabTravelInfo {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px dotted black;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .seatInfo {
  display: flex;
  gap: 20px;
  padding: 10px;
  border-bottom: 1px dotted black;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .seatInfo .iconContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 15px;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .seatInfo .seatNumber {
  display: flex;
  gap: 10px;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .seatInfo .seatNumber p {
  background: var(--cardBG);
  color: var(--blackColor);
  padding: 8px;
  font-weight: 600;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .cab_contact_info {
  display: flex;
  padding: 10px;
  justify-content: center;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .cab_contact_info .call_info {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .cab_contact_info .call_info .icon {
  color: rgb(34, 152, 218);
}
.bookingDetails .bookingDetailsContainer .cabDetailsContainer .cab_contact_info .call_info div span {
  font-size: 13px;
  color: rgb(34, 152, 218);
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer {
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
  background: white;
  position: relative;
  margin-top: -10px;
  border-radius: 10px;
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .bookingId {
  font-size: 12px;
  border-bottom: 1px dotted black;
  padding: 10px;
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .campDetails {
  padding: 10px;
  border-bottom: 1px dotted black;
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .campDetails .campAddress {
  font-size: 12px;
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .checkInCheckOut {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px dotted black;
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .checkInCheckOut .daysCount {
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid black;
  padding: 2px;
  border-radius: 5px;
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .guestDetails {
  padding: 10px;
  border-bottom: 1px dotted black;
  font-size: 13px;
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .guestDetails .roomType {
  font-size: 12px;
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .camp_contact_info {
  display: flex;
  padding: 10px;
  justify-content: center;
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .camp_contact_info .call_info {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .camp_contact_info .call_info .icon {
  color: rgb(34, 152, 218);
}
.bookingDetails .bookingDetailsContainer .campDetailsContainer .camp_contact_info .call_info div span {
  font-size: 13px;
  color: rgb(34, 152, 218);
}/*# sourceMappingURL=booking-details.css.map */