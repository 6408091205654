.loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 16px;
  -webkit-clip-path: inset(0 3ch 0 0);
          clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    -webkit-clip-path: inset(0 -1ch 0 0);
            clip-path: inset(0 -1ch 0 0);
  }
}/*# sourceMappingURL=loader-text.css.map */