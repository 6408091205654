/* Colors and styling */
:root {
    --PrimaryColor: hsl(120, 100%, 16.1%);
    --SecondaryColor: hsl(145, 63%, 43%);
    --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 248, 246);
    --cardBG: rgb(225, 225, 235);
}

.bookingHistory {
    width: 100%;
    display: flex;
    min-height: 70vh;
    position: relative;
    min-height: 70vh;
    background-image: url('Assets/booking_details_bg.jpeg');
    background-size: cover;

    .bookingHistoryContainer {
        width: 100%;
        margin-top: 40px;
        padding: 20px;
        padding-top: 3rem;

        .bookingsListContainer {
            padding: 30px;
            gap: 20px;
            width: 100%;

            .bookingList {
                border-radius: 10px;
                background: var(--cardBG);
                min-height: 20vh;

                .generalInfo {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    text-align: center;
                    width: 100%;
                    padding: 5px;
                    font-size: 14px;
                    color: var(--blackColor);

                    .btnContainer {
                        margin-top: 10px;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        gap: 2px;
                        .btn {
                            border: 1px solid var(--greyText);
                            background: var(--whiteColor);
                            color: var(--blackColor);
                            font-size: 10px;
                        }
                    }

                    .title {
                        font-weight: 600;
                        font-size: 1rem;
                    }

                    .nights {
                        background: var(--whiteColor);
                        font-weight: 600;

                        .text {
                            border: 2px solid var(--greyText);
                            background: var(--gradientColor);
                            color: var(--whiteColor);
                        }
                    }
                }

                .imageContainer {
                    width: 30%;

                    img {
                        border-radius: 15px;
                        height: 150px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 800px) {
    .bookingHistory {
        .bookingHistoryContainer {
            .bookingsListContainer {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .bookingHistory {
        .bookingHistoryContainer {
            .bookingsListContainer {
                padding: 5px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .bookingHistory {
        .bookingHistoryContainer {
            .bookingsListContainer {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .bookingHistory {
        .bookingHistoryContainer {
            .bookingsListContainer {
                padding: 20px;
            }
        }
    }
}