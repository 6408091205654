    .reviewsCard {
        border-radius: 10px;
        position: relative;
        display: flex;
        overflow: hidden;
        height: 45vh;
        margin: 20px;
        background-color: #000000;
        color: #ffffff;
        align-items: center;
        text-align: center;
        font-size: 12px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

        img {
            height: 100%;
            width: 100%;
            vertical-align: top;
            opacity: 0.5;
            object-fit: cover;
            transition: 2s ease;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }

        figcaption {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 25px 10% 15%;

            blockquote {
                padding: 0;
                margin: 0;
                font-style: italic;
                font-size: 12px;
            }

            h3 {
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                margin: 3px 0;
                border: 2px solid;

                .icon {
                    font-size: 1rem;
                }
            }
        }
    }


.reviewsCard * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

 /* the slides */
//  .slick-slide {
//     margin: 0 27px;
// }

// /* the parent */
// .slick-list {
//     margin: 0 -27px;
// }