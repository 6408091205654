/* Colors and styling */
:root {
  --PrimaryColor: hsl(120, 100%, 16.1%);
  --SecondaryColor: hsl(145, 63%, 43%);
  --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 248, 246);
  --cardBG: rgb(225, 225, 235);
}

.bookNowContainer {
  display: flex;
  justify-content: center;
}
.bookNowContainer .bookNowForm {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4000;
  background: var(--whiteColor);
  overflow-y: hidden;
}
.bookNowContainer .bookNowForm .bookNowFormContainer {
  padding: 40px;
  border-top: 2px solid var(--greyText);
  margin-top: 5rem;
  border-radius: 10px;
}
.bookNowContainer .bookNowForm .closeForm {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}
.bookNowContainer .bookNowForm h2 {
  font-size: 30px;
  color: var(--blackColor);
}
.bookNowContainer .bookNowForm label {
  display: block;
  color: var(--textColor);
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
}
.bookNowContainer .bookNowForm .inputContainer {
  position: relative;
  width: 100%;
  margin-top: 10px;
}
.bookNowContainer .bookNowForm .inputContainer .input {
  height: 40px;
  padding: 0 1rem;
  background: var(--inputColor);
  border-radius: 3rem;
}
.bookNowContainer .bookNowForm .inputContainer .input input,
.bookNowContainer .bookNowForm .inputContainer .input textarea {
  background: none;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}
.bookNowContainer .bookNowForm .inputContainer .input input::-moz-placeholder, .bookNowContainer .bookNowForm .inputContainer .input textarea::-moz-placeholder {
  font-size: 13px;
}
.bookNowContainer .bookNowForm .inputContainer .input input::placeholder,
.bookNowContainer .bookNowForm .inputContainer .input textarea::placeholder {
  font-size: 13px;
}
.bookNowContainer .bookNowForm .inputContainer .input textarea {
  margin-top: 20px;
}
.bookNowContainer .bookNowForm .inputContainer .input textarea::-moz-placeholder {
  font-size: 13px;
}
.bookNowContainer .bookNowForm .inputContainer .input textarea::placeholder {
  font-size: 13px;
}
.bookNowContainer .bookNowForm .inputContainer .input .icon {
  font-size: 18px;
  color: var(--blackColor);
  margin-left: 10px;
}
.bookNowContainer .bookNowForm .inputContainer .sendButton {
  position: absolute;
  background: var(--gradientColor);
  width: -moz-max-content;
  width: max-content;
  -moz-column-gap: 0.8rem;
       column-gap: 0.8rem;
  padding: 5px 1rem;
  border-radius: 3rem;
  left: 50%;
  transform: translate(-50%);
  justify-content: center;
  cursor: pointer;
}
.bookNowContainer .bookNowForm .inputContainer .sendButton:hover {
  background: var(--PrimaryColor);
}
.bookNowContainer .bookNowForm .inputContainer .sendButton .icon {
  font-size: 18px;
  color: var(--whiteColor);
}
.bookNowContainer .bookNowForm .inputContainer .sendButton span {
  font-size: 13px;
  color: var(--whiteColor);
}/*# sourceMappingURL=book-now.css.map */