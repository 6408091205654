/* Colors and styling */
:root {
    --PrimaryColor: hsl(120, 100%, 16.1%);
    --SecondaryColor: hsl(145, 63%, 43%);
    --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 248, 246);
    --cardBG: rgb(225, 225, 235);
}

.terms {
    width: 100%;
    background: var(--cardBG);

    .termsMainContainer {
        padding: 40px;
        background: var(--whiteColor);
        margin-top: 40px;
        margin-left: 15px;
        margin-right: 15px;
        box-shadow: 4px 4px 25px rgb(51 51 51 / 19%);

        .contentTitle {
            text-align: center;

            h2 {
                font-size: 30px;
                position: relative;
                color: var(--blackColor);
                text-transform: uppercase;
                border-bottom: 5px solid var(--cardBG);
                border-radius: 10px;
            }

        }

        .termsListContainer {
            margin-top: 10px;
            color: var(--blackColor);

            .termsContent {

                .termsList {
                    padding: 10px;
                    list-style-type: circle !important;
                }
            }
        }
    }
}