/* Colors and styling */
:root {
    --PrimaryColor: hsl(120, 100%, 16.1%);
    --SecondaryColor: hsl(145, 63%, 43%);
    --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 248, 246);
    --cardBG: rgb(225, 225, 235);
}


.bookNowContainer {
    display: flex;
    justify-content: center;

    .bookNowForm {        
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 4000;
        background: var(--whiteColor);
        overflow-y: hidden;

        .bookNowFormContainer {
            padding: 40px;
            border-top: 2px solid var(--greyText);
            margin-top: 5rem;
            border-radius: 10px;
        }

        .closeForm {
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px;
        }

        h2 {
            font-size: 30px;
            color: var(--blackColor);
        }

        label {
            display: block;
            color: var(--textColor);
            padding-bottom: 10px;
            font-size: 13px;
            font-weight: 500;
        }

        .inputContainer {
            position: relative;
            width: 100%;
            margin-top: 10px;

            .input {
                height: 40px;
                padding: 0 1rem;
                background: var(--inputColor);
                border-radius: 3rem;

                input,
                textarea {
                    background: none;
                    border: none;
                    outline: none;
                    resize: none;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 500;

                    &::placeholder {
                        font-size: 13px;
                    }
                }

                textarea {
                    margin-top: 20px;

                    &::placeholder {
                        font-size: 13px;
                        // padding: 5px;
                    }
                }

                .icon {
                    font-size: 18px;
                    color: var(--blackColor);
                    margin-left: 10px;
                }
            }

            .sendButton {
                position: absolute;
                background: var(--gradientColor);
                width: max-content;
                column-gap: .8rem;
                padding: 5px 1rem;
                border-radius: 3rem;
                left: 50%;
                transform: translate(-50%);
                justify-content: center;
                cursor: pointer;

                &:hover {
                    background: var(--PrimaryColor);
                }

                .icon {
                    font-size: 18px;
                    color: var(--whiteColor);
                }

                span {
                    font-size: 13px;
                    color: var(--whiteColor);
                }
            }
        }
    }
}