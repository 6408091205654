/* Colors and styling */
:root {
  --PrimaryColor: hsl(120, 100%, 16.1%);
  --SecondaryColor: hsl(145, 63%, 43%);
  --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 248, 246);
  --cardBG: rgb(225, 225, 235);
}

.contact {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
}
.contact .contactContent {
  max-width: 800px;
  text-align: center;
}
.contact .contactContent h2 {
  font-size: 36px;
  position: relative;
  color: var(--blackColor);
  text-transform: uppercase;
}
.contact .contactContent h2::after {
  position: absolute;
  content: "";
  background: var(--SecondaryColor);
  height: 5px;
  width: 145px;
  right: 0;
  bottom: 5px;
  z-index: -1;
}
.contact .contactContent p {
  font-weight: 300;
  color: var(--blackColor);
}
.contact .contactContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.contact .contactContainer .contactInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.contact .contactContainer .contactInfo .box {
  position: relative;
  padding: 20px 0;
  display: flex;
}
.contact .contactContainer .contactInfo .box .iconContainer {
  color: var(--whiteColor);
  min-width: 60px;
  height: 60px;
  background: var(--gradientColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.contact .contactContainer .contactInfo .box .iconContainer .icon {
  font-size: 25px;
}
.contact .contactContainer .contactInfo .box .text {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  font-size: 16px;
  color: var(--blackColor);
  font-weight: 300;
}
.contact .contactContainer .contactInfo .box .text h3 {
  font-weight: 500;
  color: var(--PrimaryColor);
}
.contact .contactContainer .contactForm {
  width: 40%;
  padding: 40px;
  background: var(--whiteColor);
}
.contact .contactContainer .contactForm h2 {
  font-size: 30px;
  color: var(--blackColor);
}
.contact .contactContainer .contactForm label {
  display: block;
  color: var(--textColor);
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
}
.contact .contactContainer .contactForm .inputContainer {
  position: relative;
  width: 100%;
  margin-top: 10px;
}
.contact .contactContainer .contactForm .inputContainer .input {
  height: 40px;
  padding: 0 1rem;
  background: var(--inputColor);
  border-radius: 3rem;
}
.contact .contactContainer .contactForm .inputContainer .input input,
.contact .contactContainer .contactForm .inputContainer .input textarea {
  background: none;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}
.contact .contactContainer .contactForm .inputContainer .input input::-moz-placeholder, .contact .contactContainer .contactForm .inputContainer .input textarea::-moz-placeholder {
  font-size: 13px;
}
.contact .contactContainer .contactForm .inputContainer .input input::placeholder,
.contact .contactContainer .contactForm .inputContainer .input textarea::placeholder {
  font-size: 13px;
}
.contact .contactContainer .contactForm .inputContainer .input textarea {
  margin-top: 20px;
}
.contact .contactContainer .contactForm .inputContainer .input textarea::-moz-placeholder {
  font-size: 13px;
}
.contact .contactContainer .contactForm .inputContainer .input textarea::placeholder {
  font-size: 13px;
}
.contact .contactContainer .contactForm .inputContainer .input .icon {
  font-size: 18px;
  color: var(--blackColor);
  margin-left: 10px;
}
.contact .contactContainer .contactForm .inputContainer .sendButton {
  position: absolute;
  background: var(--gradientColor);
  width: -moz-max-content;
  width: max-content;
  -moz-column-gap: 0.8rem;
       column-gap: 0.8rem;
  padding: 5px 1rem;
  border-radius: 3rem;
  left: 50%;
  transform: translate(-50%);
  justify-content: center;
  cursor: pointer;
}
.contact .contactContainer .contactForm .inputContainer .sendButton:hover {
  background: var(--PrimaryColor);
}
.contact .contactContainer .contactForm .inputContainer .sendButton .icon {
  font-size: 18px;
  color: var(--whiteColor);
}
.contact .contactContainer .contactForm .inputContainer .sendButton span {
  font-size: 13px;
  color: var(--whiteColor);
}

@media screen and (max-width: 991px) {
  .contact {
    padding: 50px;
  }
  .contact .contactContent {
    margin-top: 8rem;
  }
  .contact .contactContainer {
    margin-top: 30px;
    flex-direction: column;
  }
  .contact .contactContainer .contactInfo,
  .contact .contactContainer .contactForm {
    margin-bottom: 40px;
    width: 100%;
  }
  .contact .contactContainer .contactInfo .box .text,
  .contact .contactContainer .contactForm .box .text {
    font-size: 14px;
  }
}/*# sourceMappingURL=contact.css.map */