// .row {
//     // display: -ms-flexbox;
//     /* IE10 */
//     // display: flex;
//     // -ms-flex-wrap: wrap;
//     /* IE10 */
//     // flex-wrap: wrap;
//     padding: 20px;

//     /* Create four equal columns that sits next to each other */
//     .column {
//         // -ms-flex: 25%;
//         /* IE10 */
//         // flex: 25%;
//         // max-width: 25%;
//         padding: 0 4px;

//         img {
//             margin-top: 8px;
//             vertical-align: middle;
//             width: 100%;
//         }
//     }
// }

.gallary {
    width: 100%;
    // padding: 40px;
    .row {
        // padding: 40px;
        .column {
            img {
                width: 200px;
                height: 200px;
                margin-top: 8px;
                // vertical-align: middle;
                // width: 100%;
                padding: 10px;
                border-radius: 10px;
            }
        }
    }
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .column {
        // -ms-flex: 50%;
        // flex: 50%;
        // max-width: 50%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column {
        // -ms-flex: 100%;
        // flex: 100%;
        // max-width: 100%;
    }
}

@media screen and (min-width: 900px) {
    .gallary {
        .row {
            .column {
                // display: flex;
            }
        }
    }
}