/* Colors and styling */
:root {
  --PrimaryColor: hsl(120, 100%, 16.1%);
  --SecondaryColor: hsl(145, 63%, 43%);
  --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 248, 246);
  --cardBG: rgb(225, 225, 235);
}

.dashboard {
  background-image: url("Assets/dashboard_bg.jpeg");
  background-size: cover;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}
.dashboard .dashboardContainer {
  margin-top: 50px;
  padding: 30px;
}
.dashboard .dashboardContainer .backIcon {
  position: fixed;
}
.dashboard .dashboardContainer .title {
  text-align: center;
  margin-bottom: 30px;
  color: var(--whiteColorDeam);
}
.dashboard .dashboardContainer .title .quote {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: var(--SecondaryColor);
  background: var(--whiteColor);
  padding: 5px;
  box-shadow: 4px 4px 25px rgba(51, 51, 51, 0.19);
  border-radius: 10px;
}
.dashboard .dashboardContainer .dashboardContent {
  padding: 20px;
  display: flex;
}
.dashboard .dashboardContainer .dashboardContent .disabled {
  pointer-events: none;
}
.dashboard .dashboardContainer .dashboardContent .dashboardCard {
  display: flex;
  flex-direction: column;
  width: 18em;
  overflow: hidden;
  border-radius: 0.5em;
  text-decoration: none;
  background: var(--cardBG);
  margin: 1em;
  padding: 2.75em 2.5em;
  box-shadow: 0 1.5em 2.5em -0.5em rgba(0, 0, 0, 0.1);
  transition: transform 0.45s ease, background 0.45s ease;
}
.dashboard .dashboardContainer .dashboardContent .dashboardCard h3 {
  color: #2E3C40;
  font-size: 3.5em;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 0.5em;
  margin: 0 0 0.142857143em;
  border-bottom: 2px solid var(--PrimaryColor);
  transition: color 0.45s ease, border 0.45s ease;
}
.dashboard .dashboardContainer .dashboardContent .dashboardCard h4 {
  color: #627084;
  text-transform: uppercase;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  margin: 0 0 1.777777778em;
  transition: color 0.45s ease;
}
.dashboard .dashboardContainer .dashboardContent .dashboardCard .link-text {
  display: block;
  color: var(--SecondaryColor);
  font-size: 1.125em;
  font-weight: 600;
  line-height: 1.2;
  margin: auto 0 0;
  transition: color 0.45s ease;
}
.dashboard .dashboardContainer .dashboardContent .dashboardCard .disabledText {
  color: var(--cardBG);
}
.dashboard .dashboardContainer .dashboardContent .dashboardCard:hover {
  background: white;
}

@media screen and (max-width: 1000px) {
  .dashboard .dashboardContainer .dashboardContent {
    flex-direction: column;
  }
}/*# sourceMappingURL=dashboard.css.map */