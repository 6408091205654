/* Colors and styling */
:root {
  --PrimaryColor: hsl(120, 100%, 16.1%);
  --SecondaryColor: hsl(145, 63%, 43%);
  --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 248, 246);
  --cardBG: rgb(225, 225, 235);
}

.login {
  width: 100%;
  background-image: url("Assets/login_bg.jpeg");
}
.login .loginContainer {
  display: flex;
  position: relative;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 60px;
}
.login .loginContainer .loginContent {
  border-radius: 35px;
  background: var(--whiteColor);
  margin: 5% auto 15% auto;
  width: 50%;
}
.login .loginContainer .loginContent .imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}
.login .loginContainer .loginContent .imgcontainer .avatar {
  width: 30%;
  border-radius: 50%;
  background: var(--PrimaryColor);
}
.login .loginContainer .loginContent .inputcontainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.login .loginContainer .loginContent .inputcontainer .forgot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.login .loginContainer .loginContent .inputcontainer .input {
  min-width: 80%;
  height: 40px;
  padding: 0 1rem;
  background: var(--inputColor);
  border-radius: 3rem;
}
.login .loginContainer .loginContent .inputcontainer .input input[type=text],
.login .loginContainer .loginContent .inputcontainer .input input[type=password] {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
}
.login .loginContainer .loginContent .inputcontainer .input input[type=text]::-moz-placeholder, .login .loginContainer .loginContent .inputcontainer .input input[type=password]::-moz-placeholder {
  font-size: 13px;
}
.login .loginContainer .loginContent .inputcontainer .input input[type=text]::placeholder,
.login .loginContainer .loginContent .inputcontainer .input input[type=password]::placeholder {
  font-size: 13px;
}
.login .loginContainer .loginContent .inputcontainer .input .icon {
  font-size: 18px;
  color: var(--PrimaryColor) !important;
  margin-left: 10px;
}
.login .loginContainer .loginContent .inputcontainer button {
  min-width: 80%;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  height: 40px;
  border-radius: 35px;
  background: var(--gradientColor);
  color: var(--whiteColor);
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
}
.login .loginContainer .loginContent .inputcontainer button .icon {
  font-size: 1rem;
}

@media screen and (max-width: 800px) {
  .login .loginContainer .loginContent {
    width: 80%;
  }
}
@media screen and (min-width: 800px) {
  .login .loginContainer .loginContent .inputcontainer input[type=text],
  .login .loginContainer .loginContent .inputcontainer input[type=password],
  .login .loginContainer .loginContent .inputcontainer button {
    width: 60%;
  }
}/*# sourceMappingURL=auth.css.map */