.gallary {
  width: 100%;
}
.gallary .row .column img {
  width: 200px;
  height: 200px;
  margin-top: 8px;
  padding: 10px;
  border-radius: 10px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other *//*# sourceMappingURL=gallary.css.map */