/* Colors and styling */
:root {
    --PrimaryColor: hsl(120, 100%, 16.1%);
    --SecondaryColor: hsl(145, 63%, 43%);
    --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 248, 246);
    --cardBG: rgb(225, 225, 235);
}

.navbarSection {
    position: fixed;
    z-index: 3000;
    width: 100%;

    .infoNavBar {
        padding: 10px;
        background: black;
        color: var(--whiteColor);
        display: flex;
        justify-content: space-between;

        .mobile {
            a {
                color: white;
            }
        }

        .socialIcons {
            display: flex;
            gap: 8px;

            svg {
                font-size: 1.5rem;
                cursor: pointer;
            }
        }


    }

    .authNavBar {
        padding: 10px;
        background: var(--SecondaryColor);
        color: var(--whiteColor);
        display: flex;
        justify-content: space-between;
        position: relative;
        top: 75px;

        .profile-back-btn {
            .icon {
                font-size: 1.5rem;
                color: var(--whiteColor);
                cursor: pointer;
            }
        }

        .profileSection {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            font-weight: 600;
            cursor: pointer;
            color: var(--whiteColor);

            svg {
                font-size: 18px;
                cursor: pointer;
            }
        }
    }
}

.header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--PrimaryColor);
    width: 100%;
    padding-right: 1rem;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(2, 17, 33, 0.904);

    .logo {
        color: var(--blackColor);
        font-weight: 600;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        .icon {
            font-size: 25px;
            color: var(--PrimaryColor);
            transform: translateY(5px);
        }
    }

    @media screen and (max-width: 768px) {
        .navBar {
            position: absolute;
            background: var(--whiteColorDeam);
            height: 100vh;
            width: 100%;
            border-radius: 1rem;
            top: -500rem;
            left: 50%;
            padding: 1rem;
            transform: translate(-50%);
            z-index: 2000;
            box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
            transition: .5s ease-in-out;

            .navLists {
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin: auto;
                padding: .5rem 0;

                .navItem {
                    padding: .5rem 0;

                    .navLink {
                        color: var(--textColor);
                        font-size: .9rem;
                        font-weight: 600;

                        &:hover {
                            color: var(--PrimaryColor);
                        }
                    }
                }

                .btn {
                    margin-top: 1rem;

                    a {
                        font-weight: 600;
                        color: var(--whiteColor);
                    }
                }
            }

            .closeNavbar {
                position: absolute;
                top: 1rem;
                right: 1.5rem;
                color: var(--PrimaryColor);

                &:hover {
                    color: var(--SecondaryColor);

                }
            }
        }

        .activeNavbar {
            top: 0;
        }
    }

    .toggleNavbar {
        .icon {
            font-size: 25px;
            color: var(--whiteColorDeam);
        }

        &:hover.icon {
            color: var(--SecondaryColor);
        }
    }
}

@media screen and (max-width: 600px) {
    .header {
        .navBar {
            .navLists {
                .navItem {
                    .navLink {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}

// Media Queries
@media screen and (min-width: 769px) {

    .toggleNavbar,
    .closeNavbar {
        display: none;
    }

    .header {
        .navBar {
            .navLists {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;

                .navItem {
                    .navLink {
                        color: var(--whiteColor);
                        padding: 0 0.45rem;
                        font-size: 0.9rem;
                        font-weight: 500;

                        &:hover {
                            color: var(--whiteColorDeam);
                        }
                    }
                }

                .btn {
                    margin: 1rem;

                    a {
                        color: var(--whiteColor);
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1240px) {
    .header {
        padding-left: 1rem 5.5rem;
        padding-right: 1rem 5.5rem;
    }
}