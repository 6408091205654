/* Colors and styling */
:root {
    --PrimaryColor: hsl(120, 100%, 16.1%);
    --SecondaryColor: hsl(145, 63%, 43%);
    --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 248, 246);
    --cardBG: rgb(225, 225, 235);
}

.contact {
    position: relative;
    min-height: 100vh;
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;

    .contactContent {
        max-width: 800px;
        text-align: center;
        // margin-top: 8rem;

        h2 {
            font-size: 36px;
            position: relative;
            color: var(--blackColor);
            text-transform: uppercase;

            &::after {
                position: absolute;
                content: '';
                background: var(--SecondaryColor);
                height: 5px;
                width: 145px;
                right: 0;
                bottom: 5px;
                z-index: -1;
            }
        }

        p {
            font-weight: 300;
            color: var(--blackColor);
        }
    }

    .contactContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        .contactInfo {
            width: 50%;
            display: flex;
            flex-direction: column;

            .box {
                position: relative;
                padding: 20px 0;
                display: flex;

                .iconContainer {
                    color: var(--whiteColor);
                    min-width: 60px;
                    height: 60px;
                    background: var(--gradientColor);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;

                    .icon {
                        font-size: 25px;
                    }
                }

                .text {
                    display: flex;
                    margin-left: 20px;
                    flex-direction: column;
                    font-size: 16px;
                    color: var(--blackColor);
                    font-weight: 300;

                    h3 {
                        font-weight: 500;
                        color: var(--PrimaryColor);
                    }
                }
            }
        }

        .contactForm {
            width: 40%;
            padding: 40px;
            background: var(--whiteColor);

            h2 {
                font-size: 30px;
                color: var(--blackColor);
                // font-weight: 500;

                // font-size: 1.8rem;
            }

            label {
                display: block;
                color: var(--textColor);
                padding-bottom: 10px;
                font-size: 13px;
                font-weight: 500;
            }

            .inputContainer {
                position: relative;
                width: 100%;
                margin-top: 10px;

                .input {
                    height: 40px;
                    padding: 0 1rem;
                    background: var(--inputColor);
                    border-radius: 3rem;

                    input,
                    textarea {
                        background: none;
                        border: none;
                        outline: none;
                        resize: none;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 500;

                        &::placeholder {
                            font-size: 13px;
                        }
                    }

                    textarea {
                        margin-top: 20px;

                        &::placeholder {
                            font-size: 13px;
                            // padding: 5px;
                        }
                    }

                    .icon {
                        font-size: 18px;
                        color: var(--blackColor);
                        margin-left: 10px;
                    }
                }

                .sendButton {
                    position: absolute;
                    background: var(--gradientColor);
                    width: max-content;
                    column-gap: .8rem;
                    padding: 5px 1rem;
                    border-radius: 3rem;
                    left: 50%;
                    transform: translate(-50%);
                    justify-content: center;
                    cursor: pointer;

                    &:hover {
                        background: var(--PrimaryColor);
                    }

                    .icon {
                        font-size: 18px;
                        color: var(--whiteColor);
                    }

                    span {
                        font-size: 13px;
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .contact {
        padding: 50px;

        .contactContent {
            margin-top: 8rem;
        }

        .contactContainer {
            margin-top: 30px;
            flex-direction: column;

            .contactInfo,
            .contactForm {
                margin-bottom: 40px;
                width: 100%;

                .box {
                    .text {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}