/* Colors and styling */
:root {
    --PrimaryColor: hsl(120, 100%, 16.1%);
    --SecondaryColor: hsl(145, 63%, 43%);
    --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 248, 246);
    --cardBG: rgb(225, 225, 235);
}

.profile {
    width: 100%;
    min-height: 60vh;
    justify-content: center;
    align-items: center;

    // .secTitle {
    //     padding: 3rem;
    //     text-align: center;
    // }

    .profileContainer {
        .userImageSection {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-bottom: 2px solid var(--cardBG);
            padding: 3rem;

            .image {
                padding-top: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;

                .user-profile {
                    width: 40%;
                }
            }

            .emailInfo {
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .user-name {
                    font-weight: 600;
                }
            }
        }

        .userInfoSection {
            .cardDiv {
                // position: relative;
                padding: 2rem;
                background: var(--whiteColor);
                border-radius: 10px;
                gap: 1rem;
                justify-content: center;
                width: 100%;

                .userInput {
                    width: 100%;

                    .input {
                        height: 40px;
                        padding: 0 1rem;
                        background: var(--inputColor);
                        border-radius: 3rem;

                        input {
                            background: none;
                            border: none;
                            outline: none;
                            width: 100%;
                            font-size: 13px;
                            font-weight: 500;

                            &::placeholder {
                                font-size: 13px;
                            }
                        }

                        .icon {
                            font-size: 18px;
                            color: var(--PrimaryColor) !important;
                            margin-left: 10px;
                        }
                    }
                }

                .searchOptions {
                    position: absolute;
                    background: var(--gradientColor);
                    width: max-content;
                    bottom: -0.7rem;
                    column-gap: .8rem;
                    padding: 5px 1rem;
                    border-radius: 3rem;
                    left: 50%;
                    transform: translate(-50%);
                    justify-content: center;
                    cursor: pointer;

                    &:hover {
                        background: var(--PrimaryColor);
                    }

                    .icon {
                        font-size: 18px;
                    }

                    span {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    .profile {
        .profileContainer {
            .userInfoSection {
                .cardDiv {
                    flex-direction: column;
                }
            }
        }
    }
    
}