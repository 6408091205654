/* Colors and styling */
:root {
    --PrimaryColor: hsl(120, 100%, 16.1%);
    --SecondaryColor: hsl(145, 63%, 43%);
    --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 248, 246);
    --cardBG: rgb(225, 225, 235);
}

.about {
    width: 100%;
    position: relative;
    min-height: 80vh;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .aboutTitle {
        margin-top: 13rem;
        h2 {
            font-size: 36px;
            position: relative;
            color: var(--blackColor);
            text-transform: uppercase;

            &::after {
                position: absolute;
                content: '';
                background: var(--SecondaryColor);
                height: 5px;
                width: 145px;
                right: 0;
                bottom: 5px;
                z-index: -1;
            }
        }
    }

    .aboutContainer {
        margin-bottom: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8rem;

        .imageSection {
            width: 40%;
            height: auto;
            margin: auto;

            img {
                height: 280px;
                width: 280px;
                border-radius: 50%;
                border: 1px solid var(--whiteColor);
                display: block;
                margin: auto;
            }
        }

        article{
            width: 90%;
            h3{
                background: var(--gradientColor);
                font-size: 22px;
                font-weight: 600;
                color: var(--whiteColorDeam);
                border-radius: 10%;
                text-align: center;
            }

            p{
                background: var(--whiteColor);
                padding: 10px;
                font-size: 17px;
                color: var(--blackColor);
            }
        }

    }
}

@media screen and (min-width: 768px) {
    .about{
        .aboutTitle {
            margin-top: 12rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .about {
        padding: 20px 30px;

        .aboutTitle {
            margin-top: 12rem;
            h2{
                font-size: 28px;
                text-align: center;
            }
        }

        .aboutContainer {
            flex-direction: column;
            margin-top: 6rem;
            .imageSection {
                width: 90%;
                img {
                    height: 200px;
                    width: 200px;
                }
            }

            article {
                margin-top: 30px;
                width: 100%;

                h3 {
                    font-size: 18px;
                }

                p {
                    font-size: 13px;
                }
            }
        }
    }
}

@media screen and (min-width: 750px) {
    .about{
        .aboutContainer {
            .imageSection {
                width: 65%;
            }
        }
    }
}

@media screen and (min-width: 1240px) {
    .about{
        width: 90%;

        .aboutContainer {
            .imageSection {
                width: 40%;
            }
        }
    }
}