/* Colors and styling */
:root {
  --PrimaryColor: hsl(120, 100%, 16.1%);
  --SecondaryColor: hsl(145, 63%, 43%);
  --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 248, 246);
  --cardBG: rgb(225, 225, 235);
}

.tour {
  width: 100%;
  height: auto;
  background-color: rgb(245, 245, 245);
  font-family: poppins;
  padding: 50px 0;
  display: grid;
  place-items: center;
}
.tour .inclusionExclusionContainer {
  border-top: 1px solid var(--cardBG);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5rem;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
}
.tour .inclusionExclusionContainer .inclusionContainer {
  box-shadow: 4px 4px 25px rgba(51, 51, 51, 0.19);
  padding: 10px;
}
.tour .inclusionExclusionContainer .inclusionContainer .inclusionTitle {
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px solid var(--cardBG);
}
.tour .inclusionExclusionContainer .inclusionContainer .includedList {
  font-size: 14px;
}
.tour .inclusionExclusionContainer .inclusionContainer .includedList .includeListing {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
}
.tour .inclusionExclusionContainer .inclusionContainer .includedList .includeListing svg {
  background: var(--SecondaryColor);
  color: var(--whiteColor);
  border-radius: 50%;
  padding: 2px;
  font-size: 20px;
}
.tour .tourInfo {
  margin-top: 6rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.tour .tourInfo .tourTitle {
  text-align: center;
  background: white;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 5px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 3px;
}
.tour .tourInfo .tourLocations {
  margin-top: 10px;
  padding: 5px;
}
.tour .tourInfo .tourAdditionalService {
  padding: 8px;
  border-top: 1px solid var(--cardBG);
}
.tour .tourInfo .durationPriceContainer {
  display: flex;
  gap: 1rem;
  border: 1px solid var(--SecondaryColor);
  background: var(--whiteColorDeam);
  padding: 1rem;
}
.tour .tourInfo .durationPriceContainer .divider {
  border: 1px dotted var(--SecondaryColor);
}
.tour .tourInfo .durationPriceContainer .durationContainer .key {
  font-weight: 600;
}
.tour .tourInfo .durationPriceContainer .durationContainer .value {
  display: flex;
  align-items: center;
  gap: 2px;
}
.tour .tourInfo .durationPriceContainer .priceContainer .key {
  font-weight: 600;
}
.tour .tourInfo .durationPriceContainer .priceContainer .value {
  display: flex;
  align-items: center;
  gap: 2px;
}
.tour .heading {
  margin-top: 50px;
  font-size: 27px;
  font-weight: 500;
  color: var(--PrimaryColor);
  position: relative;
  margin-bottom: 80px;
}
.tour .heading::after {
  content: " ";
  position: absolute;
  width: 50%;
  height: 4px;
  left: 50%;
  bottom: -5px;
  background-image: var(--gradientColor);
  transform: translateX(-50%);
}

/* Container Css Start  */
.tourDetailContainer {
  width: 70%;
  height: auto;
  position: relative;
}

.tourDetailContainer ul::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  background-image: var(--gradientColor);
}

.tourDetailContainer ul {
  list-style: none;
}

.tourDetailContainer ul li {
  width: 50%;
  height: auto;
  padding: 15px 20px;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 4px 4px 25px rgba(51, 51, 51, 0.192);
  position: relative;
  z-index: 99;
}

.tourDetailContainer ul li:nth-child(odd) {
  float: left;
  clear: right;
  transform: translateX(-30px);
}

.tourDetailContainer ul li:nth-child(odd) .date {
  right: 20px;
}

.tourDetailContainer ul li:nth-child(even) {
  float: right;
  clear: left;
  transform: translateX(30px);
}

.tourDetailContainer ul li:nth-child(4) {
  margin-bottom: 0;
}

.tourDetailContainer ul li .title {
  font-size: 20px;
  font-weight: 500;
  color: var(--PrimaryColor);
}

ul li p {
  font-size: 15px;
  color: #444;
  margin: 7px 0;
  line-height: 23px;
}

ul li .date {
  position: absolute;
  top: -45px;
  width: 135px;
  height: 35px;
  border-radius: 20px;
  color: #fff;
  background-image: var(--gradientColor);
  display: grid;
  place-items: center;
  font-size: 14px;
}

.tourDetailContainer ul li .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--SecondaryColor);
  position: absolute;
  top: 0;
}

.tourDetailContainer ul li .circle::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: var(--whiteColor);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tourDetailContainer ul li:nth-child(odd) .circle {
  right: -30px;
  transform: translate(50%, -50%);
}

.tourDetailContainer ul li:nth-child(even) .circle {
  left: -30px;
  transform: translate(-50%, -50%);
}

/*  Media Query Started  */
@media screen and (max-width: 1224px) {
  .tourDetailContainer {
    width: 85%;
  }
}
@media screen and (max-width: 993px) {
  .tourDetailContainer {
    width: 80%;
    transform: translateX(15px);
  }
  .tourDetailContainer ul::after {
    left: -30px;
  }
  .tourDetailContainer ul li {
    width: 100%;
    float: none;
    clear: none;
    margin-bottom: 80px;
  }
  .tourDetailContainer ul li:nth-child(odd) {
    text-align: left;
    transform: translateX(0);
    margin-top: 5rem;
  }
  .tourDetailContainer ul li:nth-child(odd) .date {
    left: 20px;
  }
  .tourDetailContainer ul li:nth-child(odd) .circle {
    left: -30px;
    transform: translate(-50%, -50%);
  }
  .tourDetailContainer ul li:nth-child(even) .circle {
    left: -30px;
  }
  .tourDetailContainer ul li:nth-child(even) {
    transform: translateX(0);
  }
}/*# sourceMappingURL=tour-details.css.map */