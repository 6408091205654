/* Colors and styling */
:root {
    --PrimaryColor: hsl(120, 100%, 16.1%);
    --SecondaryColor: hsl(145, 63%, 43%);
    --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 248, 246);
    --cardBG: rgb(225, 225, 235);
}

.login {
    width: 100%;
    background-image: url('Assets/login_bg.jpeg');

    .loginContainer {
        display: flex;
        position: relative;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-top: 60px;

        .loginContent {
            border-radius: 35px;
            background: var(--whiteColor);
            margin: 5% auto 15% auto;
            width: 50%;

            .imgcontainer {
                text-align: center;
                margin: 24px 0 12px 0;
                position: relative;

                .avatar {
                    width: 30%;
                    border-radius: 50%;
                    background: var(--PrimaryColor);
                }
            }

            .inputcontainer {
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 15px;

                .forgot {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                }

                .input {
                    min-width: 80%;
                    height: 40px;
                    padding: 0 1rem;
                    background: var(--inputColor);
                    border-radius: 3rem;

                    input[type=text],
                    input[type=password] {
                        background: none;
                        border: none;
                        outline: none;
                        width: 100%;
                        font-size: 13px;
                        font-weight: 500;

                        &::placeholder {
                            font-size: 13px;
                        }
                    }

                    .icon {
                        font-size: 18px;
                        color: var(--PrimaryColor) !important;
                        margin-left: 10px;
                    }
                }


                button {
                    min-width: 80%;
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                    align-items: center;
                    height: 40px;
                    border-radius: 35px;
                    background: var(--gradientColor);
                    color: var(--whiteColor);
                    color: white;
                    padding: 14px 20px;
                    margin: 8px 0;
                    border: none;
                    cursor: pointer;
                    // width: 100%;
                    .icon {
                        font-size: 1rem;
                    }

                }

            }
        }
    }
}

@media screen and (max-width: 800px) {
    .login {
        .loginContainer {
            .loginContent {
                width: 80%;
            }
        }
    }
}

@media screen and (min-width: 800px) {
    .login {
        .loginContainer {
            .loginContent {
                .inputcontainer {

                    input[type=text],
                    input[type=password],
                    button {
                        width: 60%;
                    }
                }
            }
        }
    }
}