/* Colors and styling */
:root {
  --PrimaryColor: hsl(120, 100%, 16.1%);
  --SecondaryColor: hsl(145, 63%, 43%);
  --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 248, 246);
  --cardBG: rgb(225, 225, 235);
}

.profile {
  width: 100%;
  min-height: 60vh;
  justify-content: center;
  align-items: center;
}
.profile .profileContainer .userImageSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--cardBG);
  padding: 3rem;
}
.profile .profileContainer .userImageSection .image {
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile .profileContainer .userImageSection .image .user-profile {
  width: 40%;
}
.profile .profileContainer .userImageSection .emailInfo {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.profile .profileContainer .userImageSection .emailInfo .user-name {
  font-weight: 600;
}
.profile .profileContainer .userInfoSection .cardDiv {
  padding: 2rem;
  background: var(--whiteColor);
  border-radius: 10px;
  gap: 1rem;
  justify-content: center;
  width: 100%;
}
.profile .profileContainer .userInfoSection .cardDiv .userInput {
  width: 100%;
}
.profile .profileContainer .userInfoSection .cardDiv .userInput .input {
  height: 40px;
  padding: 0 1rem;
  background: var(--inputColor);
  border-radius: 3rem;
}
.profile .profileContainer .userInfoSection .cardDiv .userInput .input input {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
}
.profile .profileContainer .userInfoSection .cardDiv .userInput .input input::-moz-placeholder {
  font-size: 13px;
}
.profile .profileContainer .userInfoSection .cardDiv .userInput .input input::placeholder {
  font-size: 13px;
}
.profile .profileContainer .userInfoSection .cardDiv .userInput .input .icon {
  font-size: 18px;
  color: var(--PrimaryColor) !important;
  margin-left: 10px;
}
.profile .profileContainer .userInfoSection .cardDiv .searchOptions {
  position: absolute;
  background: var(--gradientColor);
  width: -moz-max-content;
  width: max-content;
  bottom: -0.7rem;
  -moz-column-gap: 0.8rem;
       column-gap: 0.8rem;
  padding: 5px 1rem;
  border-radius: 3rem;
  left: 50%;
  transform: translate(-50%);
  justify-content: center;
  cursor: pointer;
}
.profile .profileContainer .userInfoSection .cardDiv .searchOptions:hover {
  background: var(--PrimaryColor);
}
.profile .profileContainer .userInfoSection .cardDiv .searchOptions .icon {
  font-size: 18px;
}
.profile .profileContainer .userInfoSection .cardDiv .searchOptions span {
  font-size: 13px;
}

@media screen and (max-width: 1000px) {
  .profile .profileContainer .userInfoSection .cardDiv {
    flex-direction: column;
  }
}/*# sourceMappingURL=profile.css.map */