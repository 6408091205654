/* Colors and styling */
:root {
  --PrimaryColor: hsl(120, 100%, 16.1%);
  --SecondaryColor: hsl(145, 63%, 43%);
  --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 248, 246);
  --cardBG: rgb(225, 225, 235);
}

.reviews {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 2rem;
}
.reviews .reviewsContainer {
  display: grid;
  gap: 1.5rem;
  justify-content: center;
}

@media screen and (min-width: 500px) {
  .reviews .reviewsContainer {
    grid-template-columns: repeat(1, 1fr);
  }
  .reviews .reviewsContainer .reviewsCard {
    height: auto;
  }
}
@media screen and (min-width: 840px) {
  .reviews .reviewsContainer {
    grid-template-columns: repeat(3, 1fr);
  }
  .reviews .reviewsContainer .reviewsCard {
    height: auto;
  }
}/*# sourceMappingURL=home-page.css.map */