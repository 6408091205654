/* Colors and styling */
:root {
  --PrimaryColor: hsl(120, 100%, 16.1%);
  --SecondaryColor: hsl(145, 63%, 43%);
  --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 248, 246);
  --cardBG: rgb(225, 225, 235);
  --cardHover: rgb(255, 255, 255);
}

.main {
  width: 100%;
}
.main .secTitle .title {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  color: var(--textColor);
  margin: 1rem 0;
  z-index: 2;
}
.main .secTitle .title::after {
  position: absolute;
  content: "";
  background: var(--SecondaryColor);
  height: 5px;
  width: 120px;
  right: 0;
  bottom: 5px;
  z-index: -1;
}
.main .secContent {
  justify-content: center;
  gap: 1.5rem;
}
.main .secContent .singleDestination {
  height: 100%;
  display: grid;
  row-gap: 10px;
  border-radius: 10px;
  align-items: center;
  background: var(--cardBG);
  box-shadow: 0 2px 4px rbg(140, 140, 141, 0.549);
  overflow: hidden;
}
.main .secContent .singleDestination:hover {
  background: var(--cardHover);
  box-shadow: 1px 2px 4px rbg(85, 85, 114, 0.549);
  transition: 0.3s ease;
}
.main .secContent .singleDestination .imageDiv {
  height: 180px;
  width: 100%;
  overflow: hidden;
}
.main .secContent .singleDestination .imageDiv img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 2s ease;
}
.main .secContent .singleDestination .imageDiv:hover img {
  transform: scale(1.1);
}
.main .secContent .singleDestination .cardInfo {
  padding: 1rem;
}
.main .secContent .singleDestination .cardInfo .continent {
  text-align: center;
  background: var(--gradientColor);
  border-radius: 1rem;
  color: var(--whiteColor);
  padding: 1px 10px;
  justify-content: center;
  gap: 0.5rem;
}
.main .secContent .singleDestination .cardInfo .continent .icon {
  color: var(--whiteColor);
  font-size: 21px;
}
.main .secContent .singleDestination .cardInfo .continent .name {
  font-weight: 400;
  font-size: 12px;
}
.main .secContent .singleDestination .cardInfo .fees {
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem 0;
  border-top: 1.5px solid var(--greyText);
  border-bottom: 1.5px solid var(--greyText);
}
.main .secContent .singleDestination .cardInfo .fees .grade {
  color: var(--textColor);
  max-width: 200px;
}
.main .secContent .singleDestination .cardInfo .fees .grade small {
  text-align: center;
  background: var(--gradientColor);
  border-radius: 1rem;
  color: var(--whiteColor);
  padding: 1px 10px;
  font-size: 10px;
}
.main .secContent .singleDestination .cardInfo .fees .price {
  font-size: 1rem;
  color: var(--blackColor);
}
.main .secContent .singleDestination .cardInfo .desc {
  font-size: 13.5px;
  color: var(--textColor);
  font-weight: 500;
}
.main .secContent .singleDestination .cardInfo .btn {
  margin-top: 1rem;
  color: var(--whiteColor);
  font-weight: 600;
  justify-content: space-between;
  gap: 0.5rem;
  width: -moz-fit-content;
  width: fit-content;
}
.main .secContent .singleDestination .cardInfo .btn .icon {
  align-self: center;
  font-size: 18px;
}

@media screen and (min-width: 500px) {
  .title {
    font-size: 1.5rem;
  }
  .title::after {
    width: 155px;
  }
  .secContent {
    grid-template-columns: repeat(2, 1fr);
  }
  .secContent .singleDestination {
    height: auto;
  }
}
@media screen and (min-width: 840px) {
  .secContent {
    grid-template-columns: repeat(3, 1fr);
  }
  .secContent .singleDestination {
    height: auto;
  }
}/*# sourceMappingURL=main.css.map */