@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

/* Colors and styling */
:root{
    --PrimaryColor: hsl(120, 100%, 16.1%);
    --SecondaryColor: hsl(145, 63%, 43%);
    --gradientColor: linear-gradient(to right, hsl(145, 63%, 43%), hsl(120, 100%, 16.1%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 248, 246);
    --cardBG: rgb(225, 225, 235);
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

.section {
    padding: 6rem 0 2rem;
}

.container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.icon {
    font-size: 2rem;
    cursor: pointer;
}

.flex {
    display: flex;
    align-items: center;
}

.grid {
    display: grid;
    align-items: center;
}

.btn {
    padding: .6rem 1.5rem;
    background: var(--gradientColor);
    border-radius: 3rem;
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--whiteColor);
}

.btn a{
    color: var(--whiteColor);
    font-weight: 500;
}

.btn:hover{
    background: var(--SecondaryColor);
}

img, video{
    width: 100%;
    height: auto;
}

input {
    font-size: 100%;
}

body {
    background: var(--bodyColor);
}

.callbackIcon {
    left: 1rem;
    color: #fff;
    border: 2px solid white;
    bottom: 2%;
    display: flex;
    padding: 1rem;
    z-index: 3300;
    position: fixed;
    background: var(--gradientColor);
    box-shadow: 0 0 10px black;
    align-items: center;
    border-radius: 999px;
    justify-content: center;
}

.GetcallbackIcon {
    left: 1rem;
    color: #fff;
    border: 2px solid white;
    bottom: 18%;
    display: flex;
    padding: 1rem;
    z-index: 3300;
    position: fixed;
    background: var(--gradientColor);
    box-shadow: 0 0 10px black;
    align-items: center;
    border-radius: 999px;
    justify-content: center;
    cursor: pointer;
}

.callnowIcon {
    left: 1rem;
    color: #fff;
    border: 2px solid white;
    bottom: 10%;
    display: flex;
    padding: 1rem;
    z-index: 3300;
    position: fixed;
    background: var(--gradientColor);
    box-shadow: 0 0 10px black;
    align-items: center;
    border-radius: 999px;
    justify-content: center;
    cursor: pointer;
}

.callnowIcon a {
    color: var(--whiteColor);
}

.callbackIcon .icon {
    color: var(--whiteColor);
    font-size: 1.5rem;
}

.loaderConfig {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

@media screen and (min-width: 1240px) {
    .container{
        width: 80% !important;
        margin: auto !important;
    }    

    .section {
        padding: 6rem 0 4rem;
    }
}

@media screen and (min-width: 2560px) {
    body {
        zoom: 1.7;
    }
}